::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.row {
  margin: 0px;
}

.ml-5 {
  margin-left: 5px;
}

a {
  text-decoration: none;
  color: #b1b1b5;
  transition: 0.5sx;
}
a:hover {
  color: white;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #000000;
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.login .brand-logo {
  text-align: center;
}
.login .card .card-body {
  opacity: 1;
  background-color: rgba(139, 0, 0, 0);
  color: rgb(143, 0, 0);
  border: 1px solid rgb(143, 0, 0);
}
.login .form-control {
  color: rgb(121, 121, 121);
}
.login .form-control:focus {
  color: black;
}
.login .mt-3 .btn {
  background-color: rgb(143, 0, 0);
  color: white;
}

.nav-item svg {
  font-size: 20px;
}
.nav-item .count {
  top: -10px !important;
}

.menu-title {
  text-transform: capitalize;
}

.sortStyle {
  text-transform: capitalize;
}

.flexcenter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.center {
  text-align: center;
}

.block {
  display: block;
}

.btn-fw {
  padding: 5px 10px;
}

td, tr {
  text-align: center;
}

.table-container {
  overflow-x: auto;
  max-width: 400px; /* Set a maximum width for the container */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: left;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #26293b !important;
}

.alignend {
  text-align: end;
}

.card .progress, .card input {
  margin-bottom: 3px;
}
.card .error {
  display: block;
}
.card .card-title {
  text-transform: capitalize;
}
.card .select {
  padding: 1.1rem 50px;
  border: 1px solid #313452;
}
.card option {
  padding: 20px 10px !important;
}

.text-muted {
  margin-left: 10px;
  text-decoration: none;
}

.tab-content {
  border: 0px;
}

th {
  text-transform: capitalize;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card .btn {
  width: 129px;
  padding: 10px 5px;
}

.text-a {
  color: white;
  text-decoration: none;
  transition: 0.5s;
  margin-left: 5px;
}

.text-muted {
  text-transform: capitalize;
}

.sidebar .sidebar-menu-title {
  background-color: #1e1e2f;
  color: white;
  padding: 0.75rem 1.75rem;
}

.sidebar .st {
  position: fixed;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 590px;
}
.sidebar .sidebar-menu-title {
  font-weight: 800;
  text-transform: uppercase;
}

.navbar .nav-link {
  color: rgb(255, 255, 255) !important;
}
.navbar .nav-link:hover {
  color: rgb(87, 10, 10);
}

.login .brand-logo {
  text-align: center;
  margin-bottom: 1rem;
}
.login .h4 {
  text-align: center;
  color: #0e3052;
}

.target .card-body {
  padding: 10px 20px;
}
.target .card-body .col-12 {
  padding: 0px;
}

.mt-20 {
  margin-top: 20px;
}

th {
  font-weight: 100;
  min-width: 120px;
}

td {
  padding: 10px 2px;
}

.prev {
  color: #ffffff;
  border: 0px;
  font-weight: 700;
  width: 140px !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  left: 60%;
  background-color: black;
  padding: 10px;
  border-radius: 50px;
}

.select {
  padding: 18px;
}

.content-wrapper {
  padding-bottom: 10px;
}

.sidebar-icon-only .sidebar .menu-title {
  position: relative;
}

.mb-5 {
  margin-bottom: 10px;
}/*# sourceMappingURL=main.css.map */